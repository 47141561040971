var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("user-verification")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("user-verificationlist")) + " ")]), _vm.awaris_array ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_vm.awaris_array.length != 0 ? _c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v("AWARIS")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.awaris_array, function (awaris, index) {
    return _c('tbody', {
      key: awaris.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(awaris.code))]), _c('td', [_vm._v(_vm._s(awaris.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'organization.user',
          params: {
            organization_id: awaris.id
          },
          query: {
            status: 0
          }
        }
      }
    }, [_vm._v(_vm._s(awaris.pending_user_access_count))])], 1)])]);
  })], 2) : _vm._e()]) : _vm._e(), _vm.bank_array ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_vm.bank_array.length != 0 ? _c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v("Bank")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.bank_array, function (bank, index) {
    return _c('tbody', {
      key: bank.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(bank.code))]), _c('td', [_vm._v(_vm._s(bank.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'organization.user',
          params: {
            organization_id: bank.id
          },
          query: {
            status: 0
          }
        }
      }
    }, [_vm._v(_vm._s(bank.pending_user_access_count))])], 1)])]);
  })], 2) : _vm._e()]) : _vm._e(), _vm.koperasi_array ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_vm.koperasi_array.length != 0 ? _c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("cooperative")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.koperasi_array, function (koperasi, index) {
    return _c('tbody', {
      key: koperasi.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(koperasi.code))]), _c('td', [_vm._v(_vm._s(koperasi.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'organization.user',
          params: {
            organization_id: koperasi.id
          },
          query: {
            status: 0
          }
        }
      }
    }, [_vm._v(_vm._s(koperasi.pending_user_access_count))])], 1)])]);
  })], 2) : _vm._e()]) : _vm._e(), _vm.kerajaan_array ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_vm.kerajaan_array.length != 0 ? _c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("government")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.kerajaan_array, function (kerajaan, index) {
    return _c('tbody', {
      key: kerajaan.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(kerajaan.code))]), _c('td', [_vm._v(_vm._s(kerajaan.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'organization.user',
          params: {
            organization_id: kerajaan.id
          },
          query: {
            status: 0
          }
        }
      }
    }, [_vm._v(_vm._s(kerajaan.pending_user_access_count))])], 1)])]);
  })], 2) : _vm._e()]) : _vm._e(), _vm.swasta_array ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_vm.swasta_array.length != 0 ? _c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("private")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.swasta_array, function (swasta, index) {
    return _c('tbody', {
      key: swasta.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(swasta.code))]), _c('td', [_vm._v(_vm._s(swasta.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'organization.user',
          params: {
            organization_id: swasta.id
          },
          query: {
            status: 0
          }
        }
      }
    }, [_vm._v(_vm._s(swasta.pending_user_access_count))])], 1)])]);
  })], 2) : _vm._e()]) : _vm._e(), _vm.individu_array ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_vm.individu_array.length != 0 ? _c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("individual")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.individu_array, function (individu, index) {
    return _c('tbody', {
      key: individu.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(individu.code))]), _c('td', [_vm._v(_vm._s(individu.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'organization.user',
          params: {
            organization_id: individu.id
          },
          query: {
            status: 0
          }
        }
      }
    }, [_vm._v(_vm._s(individu.pending_user_access_count))])], 1)])]);
  })], 2) : _vm._e()]) : _vm._e(), _vm.pelanggan_array ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_vm.pelanggan_array.length != 0 ? _c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("client")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.pelanggan_array, function (pelanggan, index) {
    return _c('tbody', {
      key: pelanggan.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(pelanggan.code))]), _c('td', [_vm._v(_vm._s(pelanggan.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'organization.user',
          params: {
            organization_id: pelanggan.id
          },
          query: {
            status: 0
          }
        }
      }
    }, [_vm._v(_vm._s(pelanggan.pending_user_access_count))])], 1)])]);
  })], 2) : _vm._e()]) : _vm._e()])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }